import { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import styles from './index.module.scss';
import warningIcon from '/public/icons/warning.svg';
import { PASSWORD_RULES } from '../../constants/index';

type PasswordCreateProps = {
  passwordError?: string;
  setPassword: Function;
  password?: string;
  label?: string;
  hideShowPassword?: boolean;
  confirmPassword?: string;
  setConfirmPassword?: Function;
  confirmPasswordError?: string;
  setConfirmPasswordError?: Function;
  showConfirmPassword?: boolean;
  setPasswordError: Function;
};

const PasswordCreate = ({
  passwordError,
  setPassword,
  password,
  label,
  hideShowPassword,
  confirmPassword,
  confirmPasswordError,
  setConfirmPassword,
  setConfirmPasswordError,
  showConfirmPassword,
  setPasswordError,
}: PasswordCreateProps) => {
  const [showPasswordRules, setShowPasswordRules] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation('components');

  const inputType = showPassword ? 'text' : 'password';

  const onFocusInput = () => {
    setPasswordError('');
    setShowPasswordRules(true);
  };

  return (
    <>
      <div className="relative">
        <label>
          {label}
          <input
            type={inputType}
            name="password"
            required
            value={password}
            className={`${styles['password-input']} ${passwordError && 'error'}`}
            placeholder={t('PASSWORD_CREATE.PASSWORD_PLACEHOLDER')}
            onChange={(e) => setPassword(e.currentTarget.value)}
            autoComplete="new-password"
            onFocus={onFocusInput}
            autoCapitalize="off"
          />
        </label>
        {hideShowPassword && (
          <button type="button" className="naked underline" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? t('PASSWORD_CREATE.HIDE_PASSWORD') : t('PASSWORD_CREATE.SHOW_PASSWORD')}
          </button>
        )}
      </div>

      {passwordError && (
        <div className="flex pb-4">
          <Image alt="Warning icon" src={warningIcon} />
          <p className="error  pl-4">{passwordError}</p>
        </div>
      )}

      {showConfirmPassword && setConfirmPassword && setConfirmPasswordError && (
        <>
          <div className="relative">
            <label>
              Confirm {label?.toLocaleLowerCase()}
              <input
                type={inputType}
                name="confirm password"
                required
                value={confirmPassword}
                className={`${styles['password-input']} ${confirmPasswordError && 'error'}`}
                placeholder={t('PASSWORD_CREATE.PASSWORD_PLACEHOLDER')}
                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                autoComplete="new-password"
                onBlur={() => {
                  if (password !== confirmPassword) {
                    setConfirmPasswordError(t('PASSWORD_CREATE.CONFIRM_PASSWORD_ERROR_MESSAGE'));
                    return;
                  }
                  setConfirmPasswordError('');
                }}
                autoCapitalize="off"
              />
            </label>
            {hideShowPassword && (
              <button type="button" className="naked underline" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? t('PASSWORD_CREATE.HIDE_PASSWORD') : t('PASSWORD_CREATE.SHOW_PASSWORD')}
              </button>
            )}
          </div>

          {confirmPasswordError && (
            <div className="flex pb-4">
              <Image alt="Warning icon" src={warningIcon} />
              <p className="error  pl-4">{confirmPasswordError}</p>
            </div>
          )}
        </>
      )}

      {showPasswordRules && (
        <div className={styles.rules}>
          <p>{t('PASSWORD_CREATE.PASSWORD_RULES_TITLE')}</p>
          <br />
          {PASSWORD_RULES.map(({ RULE, TEXT }, index) => {
            return (
              <div className="relative flex  items-center" key={index}>
                {RULE.test(String(password ? password : '')) && (
                  <div className={styles['tick-container']}>
                    <div className={styles.tick} />
                  </div>
                )}
                <p className="pl-6">{TEXT}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default PasswordCreate;
